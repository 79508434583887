import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/coremedia-ci/documentation-site/documentation-site/src/components/ArticleLayout/index.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <ul>
      <li parentName="ul">{`Metric panels: remove filters from Contacts’ metrics; *`}</li>
      <li parentName="ul">{`Aggregation panels: Added conversions events to Conversions view; *`}</li>
      <li parentName="ul">{`Audio APP: IVR Audio Type added to Audios App;`}</li>
      <li parentName="ul">{`Distribution Skill: Added recording option;`}</li>
      <li parentName="ul">{`Bug fix: workflow schedule segmentation;`}</li>
      <li parentName="ul">{`Bug fix. Duplicated question entrance on survey answers;`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      